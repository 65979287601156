
const formacion = [
    {
        name: 'descripcion',
        value: null,
        label: 'Titulo',
        validations: 'required',
        span: 3,
        type: 'text',
    },
    // {
    //     name: 'indispensable',
    //     value: null,
    //     label: 'Indispensable',
    //     validations: 'required',
    //     type: 'select',
    //     span: 3,
    //     options: [
    //         {
    //             label: 'Si',
    //             value: true
    //         },
    //         {
    //             label: 'No',
    //             value: false
    //         }
    //     ],
    // },
    // {
    //     name: 'deseable',
    //     value: null,
    //     label: 'Deseable',
    //     validations: 'required',
    //     type: 'select',
    //     span: 3,
    //     options: [
    //         {
    //             label: 'Si',
    //             value: true
    //         },
    //         {
    //             label: 'No',
    //             value: false
    //         }
    //     ],
    // },
]

const educacion = [
    {
        name: 'tituloId',
        value: null,
        label: 'Titulo',
        validations: 'required',
        type: 'select',
        // options: llegan por fetchGet
    },
    {
        name: 'especialidad',
        value: null,
        label: 'Área y/o especialidad',
        validations: 'required',
        type: 'text',
    }
]

const experiencia = [
    {
        name: 'mesesExperiencia',
        value: null,
        label: 'Meses de experiencia',
        validations: 'required',
        type: 'number',
    },
    {
        name: 'especialidad',
        value: null,
        label: 'Especialidad',
        validations: 'required',
        type: 'text',
    },
    {
        name: 'homologacion',
        value: null,
        label: 'Homologación',
        validations: 'required',
        type: 'text',
    },
    // {
    //     name: 'cargoCritico',
    //     value: null,
    //     label: 'Cargo crítico',
    //     validations: 'required',
    //     type: 'select',
    //     options: [
    //         {
    //             label: 'Si',
    //             value: true
    //         },
    //         {
    //             label: 'No',
    //             value: false
    //         }
    //     ],
    // }
]

const funciones = [
    {
        name: 'nombre',
        value: null,
        label: 'Descripción de la función',
        validations: 'required',
        type: 'text',
    }
]

export default {
    formacion,
    experiencia,
    funciones,
    educacion
}