<script>
// import HelexiumForm from "@/components/HelexiumForm/HelexiumForm.vue";
import BVForm from "@/components/BVForm/BVForm.vue";
import schemasCargosPerfil from "./schemasCargosPerfil";
import { mapGetters } from "vuex";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  components: {
    // HelexiumForm,
    BVForm,
  },
  data() {
    return {
      schemaFormacion: null,
      formacion: [],
      schemaEducacion: null,
      educacion: [],
      schemaExperiencia: null,
      experiencia: [],
      schemaFunciones: null,
      funciones: [],
      model: {},
      opcionesEducacion: null,
      cargo: "",
      codigo: "",
      version: Number(),
      implementacion: "",
      clasificacion: "",
      objetivo: "",
      observaciones: "",
      competencias: [],
      ListNivelCompetenciaComplejidad: [],
      nivelCompetenciaComplejidadId: null,
      nivelCompetenciaComplejidadText: null,

      funcionesDeResponsabilidad: [],
      responsabilidades: [],
      nuevaFuncion: [
        {
          value: "",
          name: "nombre",
          label: "Nueva función",
          type: "text",
          validations: "required",
        },
      ],
      indexOfInputResponsabilidadToShow: null,
      selectedResponsabilidad: null,
      loaderDeleteResponsabilidad: null,
      funcionEditarIndex: null,
      funcionEditarName: "",
      empresa: {},
      sucursal: {},
    };
  },
  async mounted() {
    let { empresa } = JSON.parse(localStorage.setPersonaAct);
    let persona = JSON.parse(localStorage.setPersonaAct);

    //Esto es solo para obtener la imagen
    let ubicacionPersonaData = await this.$store.dispatch("hl_get", {
      path: "PersonaUbicacion/GetByPersona/" + persona.id,
    });
    this.sucursal = ubicacionPersonaData.sucursal;

    this.$store.getters
      .fetchGet({ path: `Cargo/GetCargo/${this.$route.params.id}` })
      .then((response) => response.json())
      .then((result) => {
        // console.log("resultX", result);
        this.cargo = this.model.cargo = result.nombre;
        this.cargoId = this.model.cargoId = result.id;
      })
      .catch((error) => console.log(error));

    this.empresa = empresa;

    const repp = await this.$store.getters.fetchGet({
      path: `Competencia/Responsabilidades/empresa/${this.$store.getters.userLoggedIn.empresa.id}`,
    });
    this.responsabilidades = await repp.json();

    this.getFuncionesResponsabilidadYCompetencias();
    this.getCompetencias();
    this.getNivelCompetenciaComplejidad();
    this.$store.getters
      .fetchGet({ path: `Cargo/GetCargo/${this.$route.params.id}` })
      .then((response) => response.json())
      .then((result) => {
        // console.log("resultX", result);
        this.cargo = this.model.cargo = result.nombre;
        this.cargoId = this.model.cargoId = result.id;
      })
      .catch((error) => console.log(error));

    this.$store.getters
      .fetchGet({ path: "PerfilCargo/titulos" })
      .then((response) => response.json())
      .then((result) => {
        const aux = [...schemasCargosPerfil.educacion];
        aux.map((element) => {
          if (element.name == "tituloId") {
            element.options = result.map((item) => {
              return {
                value: item.id,
                label: item.nombre,
              };
            });
            this.opcionesEducacion = element.options;
          }
        });
        this.schemaEducacion = aux;
      })
      .catch((error) => console.log(error));

    this.schemaFormacion = schemasCargosPerfil.formacion;
    this.schemaExperiencia = schemasCargosPerfil.experiencia;
    this.schemaFunciones = schemasCargosPerfil.funciones;

    this.$store.getters
      .fetchGet({
        path: `perfilcargo/consultarPerfilCargoAll/${this.$route.params.id}`,
      })
      .then((response) => {
        // console.log(response);
        if (response.status == 404) {
          return {};
        } else if (response.status == 200) {
          return response.json();
        }
      })
      .then((result) => {
        // console.log("result", result);
        if (Object.keys(result).length == 0) return {};

        this.codigo = result.perfilCargo.codigo;
        this.version = result.perfilCargo.version;
        this.implementacion = this.$store.getters.dateFormatInput(
          result.perfilCargo.implementacion
        );
        this.nivelCompetenciaComplejidadId =
          result.perfilCargo.nivelCompetenciaComplejidadId;
        this.clasificacion = result.perfilCargo.clasificacion;
        this.objetivo = result.perfilCargo.objetivo;
        this.observaciones = result.perfilCargo.observaciones;
        this.formacion = result.formacion;
        this.educacion = result.educacion;
        this.experiencia = result.experiencia;
        this.funciones = result.funciones;

        // this.nivelCompetenciaComplejidadText = this.ListNivelCompetenciaComplejidad[this.nivelCompetenciaComplejidadId].descripcion;

        let nivel = this.ListNivelCompetenciaComplejidad.filter(
          (x) => x.id == this.nivelCompetenciaComplejidadId
        );

        this.nivelCompetenciaComplejidadText =
          "Nivel " + nivel[0].id + ": " + nivel[0].descripcion;

        // console.log(this.funciones);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getNivelCompetenciaComplejidad() {
      this.$store.getters
        .fetchGet({
          path: `NivelCompetenciaComplejidad/ListNivelCompetenciaComplejidad/`,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error al obtener las competencias del cargo");
        })
        .then((result) => {
          this.ListNivelCompetenciaComplejidad = result;
        })
        .catch((error) => console.log(error));
    },
    selectResponsabilidad(index) {
      this.indexOfInputResponsabilidadToShow = index;
      this.selectedResponsabilidad = null;
    },
    addResponsabilidad(funcion) {
      if (this.selectedResponsabilidad != null) {
        const responsabilidad = this.responsabilidades.find(
          (item) => item.id == this.selectedResponsabilidad
        );

        const data = {
          funcionId: funcion.id,
          competenciaId: responsabilidad.id,
        };

        this.$store.getters
          .fetchPost({
            path: "PerfilCargo/setRelacionFuncionResponsabilidad",
            data,
          })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Error al agregar la responsabilidad");
          })
          .then((result) => {
            funcion.responsabilidades.push({
              id: result.competenciaId,
              title: responsabilidad.titulo,
            });
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.indexOfInputResponsabilidadToShow = null;
          });
      }
    },
    deleteResponsabilidad(funcion, responsabilidad) {
      const index = funcion.responsabilidades.indexOf(responsabilidad);
      this.loaderDeleteResponsabilidad = funcion.id;

      this.$store.getters
        .fetchDelete({
          path: `PerfilCargo/deleteRelacionFuncionResponsabilidad/${funcion.id}/${responsabilidad.id}`,
        })
        .then((response) => {
          if (response.ok) {
            funcion.responsabilidades.splice(index, 1);
            this.funcionesDeResponsabilidad = [
              ...this.funcionesDeResponsabilidad,
            ];
          }
          throw new Error("Error al eliminar la responsabilidad");
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loaderDeleteResponsabilidad = null;
        });
    },
    async eliminarFuncionDelCargo(item) {
      Swal.fire({
        title: "¿Está seguro de eliminar la función?",
        text: "Esta acción no se puede revertir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const index = this.funcionesDeResponsabilidad.indexOf(item);
          await this.$store.getters.fetchDelete({
            path: `PerfilCargo/deleteAllRelacionFuncionResponsabilidad/${item.id}`,
          });
          const resultDelete = await this.$store.getters.fetchDelete({
            path: `CargoCompetencia/EliminarFuncion/${item.id}`,
          });
          if (resultDelete.ok) {
            this.funcionesDeResponsabilidad.splice(index, 1);
          }
        }
      });
    },
    async getFuncionesResponsabilidadYCompetencias() {
      // ? Quizás este método pueda mejorar desde el backend

      const resp = await this.$store.getters.fetchGet({
        path: `PerfilCargo/FuncionesDeResponsabilidadByCargo/${this.$route.params.id}`,
      });
      this.funcionesDeResponsabilidad = await resp.json();

      const respp = await this.$store.getters.fetchGet({
        path: `PerfilCargo/FuncionesDeResponsabilidadByCargoYCompetencias/${this.$route.params.id}`,
      });
      const funcionesYCompetencias = await respp.json();

      this.funcionesDeResponsabilidad.forEach((funcion) => {
        funcion.responsabilidades = funcionesYCompetencias.filter(
          (f) => f.funcionId == funcion.id
        );
        funcion.responsabilidades = funcion.responsabilidades.map((r) => {
          return {
            id: r.competenciaId,
            title: r.competenciaTitulo,
          };
        });
      });
    },
    getCompetencias() {
      this.$store.getters
        .fetchGet({
          path: `Cargo/CompetenciasDelCargo/${this.$route.params.id}`,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error al obtener las competencias del cargo");
        })
        .then((result) => {
          this.competencias = result;
        })
        .catch((error) => console.log(error));
    },

    agregarFormacion(self) {
      let { data } = self;
      data.deseable = !!data.deseable;
      data.indispensable = !!data.indispensable;

      this.formacion.push(data);
      this.formacion = [...this.formacion];
      self.schema.forEach((item) => {
        item.value = null;
      });

      console.log(this.formacion);
    },
    deleteFormacion(index) {
      this.formacion.splice(index, 1);
    },

    agregarEducacion(self) {
      let { data } = self;
      this.educacion.push(data);
      this.educacion = [...this.educacion];
      self.schema.forEach((item) => {
        item.value = null;
      });
    },
    deleteEducacion(index) {
      this.educacion.splice(index, 1);
    },

    agregarExperiencia(self) {
      let { data } = self;
      this.experiencia.push(data);
      this.experiencia = [...this.experiencia];
      self.schema.forEach((item) => {
        item.value = null;
      });
    },
    deleteExperiencia(index) {
      this.experiencia.splice(index, 1);
    },

    agregarFuncion(self) {
      let { data } = self;
      data.tipo = "G";
      this.funciones.push(data);
      this.funciones = [...this.funciones];
      self.schema.forEach((item) => {
        item.value = null;
      });
      console.log(this.funciones);
    },
    deleteFuncion(item) {
      const index = this.funciones.indexOf(item);
      this.funciones.splice(index, 1);
    },

    saveData() {
      let perfilCargo = {
        cargoId: this.cargoId,
        codigo: this.codigo,
        version: this.version,
        implementacion: this.implementacion,
        clasificacion: this.clasificacion,
        objetivo: this.objetivo,
        observaciones: this.observaciones,
        nivelCompetenciaComplejidadId: this.nivelCompetenciaComplejidadId,
      };

      let data = {
        perfilCargo,
        formacion: this.formacion,
        educacion: this.educacion,
        experiencia: this.experiencia,
        funciones: this.funcionesGenerales(),
      };
      // console.log(data);
      this.$store.commit("setLoading", true);
      this.$store.getters
        .fetchGet({
          path: `perfilcargo/consultarPerfilCargoAll/${this.$route.params.id}`,
        })
        .then((response) => {
          if (response.status == 404) {
            return {};
          } else if (response.status == 200) {
            return response.json();
          }
        })
        .then((result) => {
          // console.log(result);
          if (Object.keys(result).length == 0) {
            this.$store.getters
              .fetchPost({
                path: "PerfilCargo/registrarPerfilCargo",
                data,
              })
              .then((response) => response.json())
              .then((result) => {
                if (result == true) {
                  Swal.fire({
                    title: "¡Éxito!",
                    text: "Se ha creado el perfil de cargo correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                  });
                }
              })
              .finally(() => {
                this.$store.commit("setLoading", false);
              });
          } else {
            this.$store.getters
              .fetchPut({
                path: `PerfilCargo/actualizarPerfilCargo/${result.perfilCargo.id}`,
                data,
              })
              .then((response) => response.json())
              .then((result) => {
                if (result == true) {
                  Swal.fire({
                    title: "¡Éxito!",
                    text: "Se ha actualizado el perfil de cargo correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                  });
                }
              })
              .finally(() => {
                this.$store.commit("setLoading", false);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "¡Error!",
            text: "Ha ocurrido un error al crear el perfil de cargo",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
          this.$store.commit("setLoading", false);
        });
    },
    showEducacionTituloById(id) {
      return this.opcionesEducacion.find((item) => item.value == id).label;
      // return id
    },
    funcionesGenerales() {
      if (this.funciones) {
        return this.funciones.filter((f) => f.tipo == "G");
      } else {
        return [];
      }
    },
    addFuncionAlCargo(self) {
      let data = {
        CargoId: this.cargoId,
        Nombre: self.data.nombre,
      };

      self.loader = true;

      this.$store.getters
        .fetchPost({ path: "CargoCompetencia/CrearFuncion", data })
        .then((response) => response.json())
        .then((result) => {
          result.responsabilidades = [];
          this.funcionesDeResponsabilidad.unshift(result);
          self.schema.forEach((item) => {
            item.value = null;
          });
        })
        .catch((error) => {
          console.log("Error:", error);
        })
        .finally(() => {
          self.loader = false;
        });
    },
    putFuncionDelCargo(item) {
      const data = {
        nombre: this.funcionEditarName,
        id: item.id,
        cargoId: this.cargoId,
        tipo: null,
      };
      console.log(item.id);
      this.$store.getters
        .fetchPut({ path: `CargoCompetencia/UpdateFuncion/${item.id}`, data })
        .then((response) => {
          if (response.ok) {
            item.nombre = this.funcionEditarName;
            this.funcionEditarIndex = null;
            this.funcionEditarName = "";
          }
        })
        .catch((error) => console.log(error));
    },
  },
  computed: {
    ...mapGetters(["getLoading"]),
    responsabilidadesNoSeleccionadas() {
      try {
        return this.responsabilidades.filter((responsabilidad) => {
          return (
            this.funcionesDeResponsabilidad[
              this.indexOfInputResponsabilidadToShow
            ].responsabilidades.find((item) => item.id == responsabilidad.id) ==
            undefined
          );
        });
      } catch {
        return this.responsabilidades;
      }
    },
    funcionesTipoG() {
      if (this.funciones) {
        return this.funciones.filter((f) => f.tipo == "G");
      } else {
        return [];
      }
    },
    funcionesTipoNotG() {
      if (this.funciones) {
        return this.funciones.filter((f) => f.tipo != "G");
      } else {
        return [];
      }
    },
    funcionesYResponsabilidades() {
      const responsabilidades = this.competencias.filter(
        (c) => c.competencia.tipoCompetencia.descripcion == "Responsabilidad"
      );

      let funciones = {};

      responsabilidades.forEach((responsabilidad) => {
        if (responsabilidad.cargoFunciones != null) {
          if (
            Object.keys(funciones).includes(
              responsabilidad.cargoFunciones.nombre
            )
          ) {
            funciones[responsabilidad.cargoFunciones.nombre].push(
              responsabilidad
            );
          } else {
            funciones[responsabilidad.cargoFunciones.nombre] = [
              responsabilidad,
            ];
          }
        } else {
          if (Object.keys(funciones).includes("Sin función")) {
            funciones["Sin función"].push(responsabilidad);
          } else {
            funciones["Sin función"] = [responsabilidad];
          }
        }
      });

      // console.log('responsabilidades', responsabilidades);

      return funciones;
    },
  },
};
</script>

<template>
  <b-container fluid>
    <div>
      <b-row class="">
        <b-col sm="12">
          <card class="container-fluid">
            <b-row>
              <div
                class="container-fluid py-2 d-flex justify-content-around align-items-center"
              >
                <!-- <div>
                  <img src="../../../assets/images/circunferencia.png" class="img-fluid bg-img-empresa" style="width: 12vw" alt="" />
                </div> -->

                <!-- :src="empresa.urlLogo" -->
                <!-- <div class="">
                  <img 
                    :src="empresa.urlLogo"
                    class="img-fluid circular--landscape img"
                    style=""
                    alt="" 
                  />
                </div> -->
                <div class="bg-dark rounded">
                  <img
                    :src="
                      sucursal.urlLogo != null
                        ? sucursal.urlLogo
                        : empresa.urlLogo
                    "
                    class="img-fluid circular--landscape img"
                    alt="logo_empresa"
                  />
                </div>
                <!-- <div class="">
                  <img 
                    :src="empresa.urlLogo"
                    class="img-fluid circular--landscape img"
                    style=""
                    alt="" 
                  />
                </div> -->
                <div>
                  <div class="text-center">
                    <span class="h2"
                      ><b>PERFIL Y FUNCIONES DE CARGO</b><br
                    /></span>
                    <span class="h3 text-light"><b>Talento Humano</b></span>
                  </div>
                </div>
                <div>
                  <b-row>
                    <b-col>
                      <div>
                        <label for="codigo" class="form-label mt-3"
                          >Código:</label
                        >
                        <input
                          required
                          v-model="codigo"
                          type="text"
                          class="form-control"
                          id=" "
                        />
                      </div>
                      <div>
                        <label for="version" class="form-label mt-3"
                          >Versión:</label
                        >
                        <input
                          required
                          v-model="version"
                          type="number"
                          class="form-control"
                          id=" "
                        />
                      </div>
                    </b-col>
                    <b-col>
                      <div>
                        <label for="implementacion" class="form-label mt-3"
                          >Implementación:</label
                        >
                        <input
                          required
                          v-model="implementacion"
                          type="date"
                          class="form-control"
                          id=" "
                        />
                      </div>
                      <div>
                        <label for="clasificacion" class="form-label mt-3"
                          >Clasificación de la información:</label
                        >
                        <input
                          required
                          v-model="clasificacion"
                          type="text"
                          class="form-control"
                          id=" "
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-row>
          </card>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>INFORMACIÓN DEL CARGO</b></h4>
            </template>
            <div class="row p-3">
              <div class="px-3 text-left mt-1">
                <p v-if="cargo">Nombre del Cargo: {{ cargo }}</p>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>

      <!-- ! Formacion -->
      <b-row class="">
        <b-col sm="12">
          <card class="">
            <div class="my-3 px-3">
              <!-- <HelexiumForm class="mb-2" title="Formación" :schema="schemaFormacion">
                <template #submit="result">
                  <b-button variant="info" @click="agregarFormacion(result)">Agregar</b-button>
                </template>
              </HelexiumForm> -->

              <BVForm
                :schema="schemaFormacion"
                :callBackSubmit="agregarFormacion"
                title="Formación"
              >
                <template #text-submit> Agregar </template>
              </BVForm>

              <b-list-group>
                <b-list-group-item
                  class="position-relative"
                  v-for="(item, index) in formacion"
                  :key="'formacion' + index"
                >
                  <!-- <button @click.prevent="deleteFormacion(index)" class="btn btn-close btn-outline-danger pl-3 mt-1">
                    <i class="fas fa-minus"></i>
                  </button> -->
                  <vs-button
                    danger
                    icon
                    @click.prevent="deleteFormacion(index)"
                    class=""
                  >
                    <i class="fas fa-minus"></i>
                  </vs-button>
                  <p><b>Titulo: </b> {{ item.descripcion }}</p>
                  <!-- <p><b>Deseable: </b> {{ item.deseable ? "Si" : "No" }}</p>
                  <p><b>Indispensable: </b> {{ item.indispensable ? "Si" : "No" }}</p> -->
                </b-list-group-item>
              </b-list-group>
            </div>
          </card>
        </b-col>
      </b-row>

      <!-- ! Educacion -->
      <b-row class="">
        <b-col sm="12">
          <card class="p-3">
            <div>
              <!-- <HelexiumForm class="mb-2" title="Educación" :schema="schemaEducacion">
                <template #submit="result">
                  <b-button variant="info" @click="agregarEducacion(result)">Agregar</b-button>
                </template>
              </HelexiumForm> -->

              <BVForm
                :schema="schemaEducacion"
                :callBackSubmit="agregarEducacion"
                title="Educación"
              >
                <template #text-submit> Agregar </template>
              </BVForm>

              <b-list-group>
                <b-list-group-item
                  class="position-relative"
                  v-for="(item, index) in educacion"
                  :key="'Educacion' + index"
                >
                  <!-- <button @click.prevent="deleteEducacion(index)" class="btn btn-close btn-outline-danger pl-3 mt-1">
                    <i class="fas fa-minus"></i>
                  </button> -->
                  <vs-button
                    danger
                    icon
                    @click.prevent="deleteEducacion(index)"
                  >
                    <i class="fas fa-minus"></i>
                  </vs-button>
                  <p>
                    <b>Titulo: </b> {{ showEducacionTituloById(item.tituloId) }}
                  </p>
                  <p><b>Área y/o especialidad: </b> {{ item.especialidad }}</p>
                </b-list-group-item>
              </b-list-group>
            </div>
          </card>
        </b-col>
      </b-row>

      <!-- ! Experiencia -->
      <b-row class="">
        <b-col sm="12">
          <card class="p-3">
            <!-- <template v-slot:headerTitle>
              <h4 class="card-title"><b>COMPETENCIAS REQUERIDAS PARA EL CARGO</b></h4>
            </template> -->
            <div>
              <!-- <HelexiumForm class="mb-2" title="Experiencia" :schema="schemaExperiencia">
                <template #submit="result">
                  <b-button variant="info" @click="agregarExperiencia(result)">Agregar</b-button>
                </template>
              </HelexiumForm> -->

              <BVForm
                :schema="schemaExperiencia"
                :callBackSubmit="agregarExperiencia"
                title="Experiencia"
              >
                <template #text-submit> Agregar </template>
              </BVForm>

              <b-list-group>
                <b-list-group-item
                  class="position-relative"
                  v-for="(item, index) in experiencia"
                  :key="'Experiencia' + index"
                >
                  <!-- <button @click.prevent="deleteExperiencia(index)" class="btn btn-close btn-outline-danger pl-3 mt-1">
                    <i class="fas fa-minus"></i>
                  </button> -->
                  <vs-button
                    @click.prevent="deleteExperiencia(index)"
                    danger
                    icon
                  >
                    <i class="fas fa-minus"></i>
                  </vs-button>
                  <p>
                    <b>Meses de experiencia: </b> {{ item.mesesExperiencia }}
                  </p>
                  <p><b>Especialidad: </b> {{ item.especialidad }}</p>
                  <p><b>Homologación: </b> {{ item.homologacion }}</p>
                  <!-- <p><b>Cargo crítico: </b> {{ item.cargoCritico ? "Si" : "No" }}</p> -->
                </b-list-group-item>
              </b-list-group>
            </div>
          </card>
        </b-col>
      </b-row>

      <!-- ! Niveles de competencia y complejidad  -->
      <b-row class="">
        <b-col sm="12">
          <card class="p-3">
            <p class="h4">
              <b
                >Nivel de
                {{
                  empresa.nombre == "Grupo GSE" ? "habilidad" : "competencia"
                }}
                y complejidad</b
              >
            </p>

            <!-- <b-dropdown variant="link" menu-class="py-1 wrap-dropdown" v-model="nivelCompetenciaComplejidadId" no-caret> -->
            <b-dropdown
              variant="none text-left p-3 border"
              class="bg-dark"
              menu-class="py-1 wrap-dropdown"
              v-model="nivelCompetenciaComplejidadId"
              no-caret
            >
              <template #button-content
                ><strong style="text-wrap: pretty">{{
                  nivelCompetenciaComplejidadText
                }}</strong>
              </template>
              <b-dropdown-item
                @click="
                  nivelCompetenciaComplejidadId = item.id;
                  nivelCompetenciaComplejidadText = item.descripcion;
                  $forceUpdate();
                "
                v-for="item of ListNivelCompetenciaComplejidad"
                :key="item.id"
              >
                <div style="width: 100%; padding: 5px">
                  {{ "Nivel " + item.id + ": " + item.descripcion }}
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <div></div>
          </card>
        </b-col>
      </b-row>
      <!-- ! Objetivo -->
      <b-row class="">
        <b-col sm="12">
          <card class="p-3">
            <p class="h4"><b>Objetivos del cargo</b></p>
            <div>
              <b-form-textarea
                id="Objetivo"
                v-model="objetivo"
                placeholder="Agregar objetivo"
                rows="5"
                max-rows="5"
              ></b-form-textarea>
            </div>
          </card>
        </b-col>
      </b-row>

      <!-- ! Funciones -->

      <b-row class="">
        <b-col sm="12">
          <card class="p-3">
            <p class="h4">
              <b>Principales funciones y responsabilidades</b>
            </p>
            <div>
              <BVForm
                :callBackSubmit="addFuncionAlCargo"
                :schema="nuevaFuncion"
              >
                <template #text-submit> Agregar </template>
              </BVForm>

              <b-list-group>
                <b-list-group-item
                  v-for="(funcion, idx) in funcionesDeResponsabilidad"
                  :key="'funcion' + idx"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-if="funcionEditarIndex != idx"
                  >
                    <p class="text-white-50 h4">
                      {{ funcion.nombre }}
                    </p>
                    <div
                      class="d-flex justify-content-right align-items-center"
                      v-if="indexOfInputResponsabilidadToShow != idx"
                    >
                      <!-- <button
                        @click.prevent="
                          funcionEditarIndex = idx;
                          funcionEditarName = funcion.nombre;
                        "
                        class="btn btn-primary"
                      >
                        Editar
                      </button> -->
                      <vs-button
                        @click.prevent="
                          funcionEditarIndex = idx;
                          funcionEditarName = funcion.nombre;
                        "
                      >
                        Editar
                      </vs-button>
                      <!-- <button @click.prevent="eliminarFuncionDelCargo(funcion)" class="btn btn-outline-danger mx-2">Eliminar</button>
                      <button class="btn btn-outline-success" @click.prevent="selectResponsabilidad(idx)">Nueva responsabilidad</button> -->
                      <vs-button
                        danger
                        @click.prevent="eliminarFuncionDelCargo(funcion)"
                        >Eliminar</vs-button
                      >
                      <vs-button
                        success
                        class="text-nowrap"
                        @click.prevent="selectResponsabilidad(idx)"
                        >Nueva responsabilidad</vs-button
                      >
                    </div>
                  </div>
                  <div v-else>
                    <div class="input-wrapper">
                      <label class="mb-1">Editar funcion</label>
                      <div class="input-inner d-flex">
                        <b-form-input
                          class="sm-input border-right-0"
                          style="border-radius: 11px 0 0 11px"
                          required
                          v-model="funcionEditarName"
                        ></b-form-input>
                        <div class="input-aside d-flex">
                          <!-- <button
                            @click.prevent="
                              funcionEditarIndex = null;
                              funcionEditarName = '';
                            "
                            class="btn btn-primary p-0 px-3 border-right-0"
                             
                          >
                            Cancelar
                          </button> -->
                          <vs-button
                            @click.prevent="
                              funcionEditarIndex = null;
                              funcionEditarName = '';
                            "
                            danger
                          >
                            Cancelar
                          </vs-button>
                          <!-- <button @click.prevent="putFuncionDelCargo(funcion)" class="btn btn-primary p-0 px-3"  >
                            Agregar
                          </button> -->
                          <vs-button
                            @click.prevent="putFuncionDelCargo(funcion)"
                          >
                            Agregar
                          </vs-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="indexOfInputResponsabilidadToShow == idx">
                    <div class="input-wrapper">
                      <label class="mb-1">Seleccionar responsabilidad</label>
                      <div class="input-inner d-flex">
                        <b-form-select
                          class="sm-input border-right-0"
                          style="border-radius: 11px 0 0 11px"
                          required
                          :options="responsabilidadesNoSeleccionadas"
                          v-model="selectedResponsabilidad"
                          value-field="id"
                          text-field="titulo"
                        ></b-form-select>
                        <div class="input-aside d-flex">
                          <!-- <button
                            @click.prevent="indexOfInputResponsabilidadToShow = null"
                            class="btn btn-primary p-0 px-3 border-right-0"
                             
                          >
                            Cancelar
                          </button> -->
                          <vs-button
                            danger
                            @click.prevent="
                              indexOfInputResponsabilidadToShow = null
                            "
                          >
                            Cancelar
                          </vs-button>
                          <!-- <button @click.prevent="addResponsabilidad(funcion)" class="btn btn-primary p-0 px-3"  >
                            Agregar
                          </button> -->
                          <vs-button
                            @click.prevent="addResponsabilidad(funcion)"
                          >
                            Agregar
                          </vs-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li
                      v-for="(
                        responsabilidad, idxResponsabilidad
                      ) in funcion.responsabilidades"
                      :key="'funcion en responsabilidad ' + idxResponsabilidad"
                      class="d-flex justify-content-between align-items-center mt-3 border-bottom border-white pb-2"
                    >
                      <b>
                        {{ responsabilidad.title }}
                      </b>
                      <div>
                        <div>
                          <!-- <button
                            @click.prevent="deleteResponsabilidad(funcion, responsabilidad)"
                            :disabled="loaderDeleteResponsabilidad == funcion.id"
                            class="btn btn-outline-danger py-0 px-3"
                          >
                            <span v-if="loaderDeleteResponsabilidad != funcion.id"> - </span>
                            <span v-else style="font-size: 0.5rem">
                              <i class="fas fa-spinner fa-spin"></i>
                            </span>
                          </button> -->
                          <vs-button
                            danger
                            icon
                            @click.prevent="
                              deleteResponsabilidad(funcion, responsabilidad)
                            "
                            :disabled="
                              loaderDeleteResponsabilidad == funcion.id
                            "
                          >
                            <span
                              v-if="loaderDeleteResponsabilidad != funcion.id"
                            >
                              <i class="fas fa-minus"></i>
                            </span>
                            <span v-else style="font-size: 0.5rem">
                              <i class="fas fa-spinner fa-spin"></i>
                            </span>
                          </vs-button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </b-list-group-item>
              </b-list-group>
            </div>
          </card>
        </b-col>
      </b-row>

      <b-row class="">
        <b-col sm="12">
          <card class="p-3">
            <p class="h4">
              <b> Funciones generales frente a los sistemas de gestión </b>
            </p>
            <p class="h6">
              (Gestión de Calidad, Gestión de Seguridad de la Información y
              Gestión de Seguridad y Salud en el Trabajo)
            </p>

            <div>
              <!-- <HelexiumForm class="mb-2" :schema="schemaFunciones">
                <template #submit="result">
                  <b-button variant="info" @click="agregarFuncion(result)">Agregar</b-button>
                </template>
              </HelexiumForm> -->
              <BVForm
                :schema="schemaFunciones"
                :callBackSubmit="agregarFuncion"
              >
                <template #text-submit> Agregar </template>
              </BVForm>
              <b-list-group>
                <b-list-group-item
                  class="position-relative"
                  v-for="(item, index) in funcionesTipoG"
                  :key="'formacion' + index"
                >
                  <div class="display-grid">
                    <div class="display-grid-body">
                      <p>
                        <b>Funcion {{ index + 1 }}: </b> {{ item.nombre }}
                      </p>
                    </div>
                    <div
                      class="display-grid-side"
                      v-if="'Sin función' != funcion"
                    >
                      <!-- <button @click.prevent="deleteFuncion(item)" class="btn btn-close btn-outline-danger pl-3 mt-1">
                        <i class="fas fa-minus"></i>
                      </button> -->
                      <vs-button
                        @click.prevent="deleteFuncion(item)"
                        danger
                        icon
                      >
                        <i class="fas fa-minus"></i>
                      </vs-button>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </card>
        </b-col>
      </b-row>

      <!-- !OBSERVACIONES  -->
      <b-row class="">
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4 class="card-title">
                <b>OBSERVACIONES (Otras especificaciones)</b>
              </h4>
            </template>
            <div class="my-3 px-3">
              <b-form-textarea
                id="Objetivo"
                v-model="observaciones"
                placeholder="Agregar objetivo"
                rows="5"
                max-rows="5"
              ></b-form-textarea>
            </div>
          </card>
        </b-col>
      </b-row>

      <div class="text-right px-3 pb-4">
        <!-- <button class="mx-1 btn px-4 py-2 btn-outline-success" :disabled="getLoading" @click.prevent="saveData">
          <i v-if="getLoading" class="fas fa-spinner fa-spin"></i>
          <span v-else> Guardar </span>
        </button> -->

        <b-button
          variant="primary"
          :disabled="getLoading"
          @click.prevent="saveData"
        >
          <i v-if="getLoading" class="fas fa-spinner fa-spin"></i>
          <span v-else style="color: inherit"> Guardar </span>
        </b-button>
      </div>
    </div>
  </b-container>
</template>

<style scoped>
.circular--landscape {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  /* border-radius: 50%; */
  object-fit: contain;
  /* background-color: #fff */
}
.circular--landscape img {
  width: auto;
  height: 100%;
  margin-left: -50px;
  object-fit: contain;
}

.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.display-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
}

.display-grid-body {
  grid-column: 1 / span 10;
}

.display-grid-side {
  grid-column: 11 / span 2;
}

.sm-input {
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 3px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .display-grid {
    display: block;
  }

  .display-grid-body {
    grid-column: 1 / span 12;
  }

  .display-grid-side {
    grid-column: 1 / span 12;
  }
}

.wrap-dropdown {
  width: 100%;
  min-width: 0;
  /* background-color: red !important; */
}

.wrap-dropdown * {
  white-space: normal;
}

.b-dropdown .btn-primary:hover,
.b-dropdown .btn-primary:active,
.b-dropdown .btn-primary:focus {
  background-color: #08284b !important;
  color: #fff !important;
}

/* Cambia el color de fondo y del texto al pasar el cursor sobre los items del menú */
.b-dropdown-menu a:hover,
.b-dropdown-menu a:active,
.b-dropdown-menu a:focus {
  background-color: #08284b !important;
  color: #fff !important;
}

.customDropdown {
  border: 3px dashed cyan;
  background-color: purple;
}
</style>
